.input_t {
    > div {
        background: rgba(126,126,126,0.1);
        position: relative;
        border-radius: 5px;
        overflow: hidden;
    }
    input {
        height: 70px;
        font-weight: 700;
        box-sizing: border-box;
        border: 2px transparent;
        display: block;
        line-height: 1.5;
        width: 100%;
        background: transparent;
        font-size: 20px;
        padding: 30px 10px 0;
    }
    span {
        opacity: 0;
        transition: all 0.25s cubic-bezier(0.4, 0, 0.2, 1); 
        color:#999;
        display: block;
        text-align: left;
        font-size: 14px;
        position: absolute;
        top:10px;
        left:10px;
        pointer-events: none;
        transform: scale(0.75);
    }
    label {
        opacity: 1;
        transition: all 0.25s cubic-bezier(0.4, 0, 0.2, 1);
        color:#999;
        display: block;
        text-align: left;
        font-size: 14px;
        position: absolute;
        top:24px;
        left:10px;
        padding-left: 10px;
        pointer-events: none;
        transform: scale(1);
    }
    &.focus {
        input {
            border-radius: 5px;
            border:2px solid #000;
            background-color: #fff;
        }
        span {
            opacity: 1;
            transform: scale(1);
        }
        label {
            opacity: 0;
            transform: scale(0.75);
        }
    }
    &.on {
        >div {
            background: #ededed;
        }
        input {
        }
        span {
            opacity: 1;
            transform: scale(1);
        }
        label {
            opacity: 0;
            transform: scale(0.75);
        }
    }
    &.on.readonly {
        >div {
            background: transparent;
        }
        input {
            border:2px solid rgba(219, 219, 219, 0.76);;
        }
        span {
            opacity: 1;
            transform: scale(1);
        }
        label {
            opacity: 0;
            transform: scale(0.75);
        }
    }

}
