.input_file {
  margin-top: 15px;
  .input_file_in {
    input {
      position: absolute;
      width: 1px;
      height: 1px;
      padding: 0;
      margin: -1px;
      overflow: hidden;
      clip: rect(0, 0, 0, 0);
      border: 0;
    }
    label {
      display: inline-block;
      text-align: center;
    }
    & ~ .input_file_in {
      label {
        display: none;
      }
    }
    & + .input_file_in.on {
      label {
        display: none;
      }
    }
    &.on + .input_file_in {
      label {
        display: inline-block;
      }
    }
    & + .input_file_in.on + .input_file_in {
      label {
        display: none;
      }
    }
    &.on + .input_file_in.on + .input_file_in {
      label {
        display: inline-block;
      }
    }
    & + .input_file_in.on + .input_file_in.on + .input_file_in {
      label {
        display: none;
      }
    }
    &.on + .input_file_in.on + .input_file_in.on + .input_file_in {
      label {
        display: inline-block;
      }
    }
    & + .input_file_in.on + .input_file_in.on + .input_file_in.on + .input_file_in {
      label {
        display: none;
      }
    }
    &.on + .input_file_in.on + .input_file_in.on + .input_file_in.on + .input_file_in {
      label {
        display: inline-block;
      }
    }
  }
  .file_item {
    display: block;
    margin: 8px 0;
    span {
      display: inline-block;
      vertical-align: middle;
      font-size: 16px;
      line-height: 1.2;
      text-align: center;
      color: #0359ff;
      text-decoration: underline;
      letter-spacing: -1px;
    }
  }
  .file_item_w {
    .file_item {
      display: block;
      margin-bottom: 10px;
      margin-left: 0;
    }
  }
  .delete {
    display: inline-block;
    vertical-align: middle;
    width: 20px;
    height: 20px;
    font: 0/0 a;
    background: url('../../../images/close_file.png') no-repeat center center;
    cursor: pointer;
    margin-left: 3px;
  }

  .alert {
    color: #111111;
    font-size: 12px;
    text-align: left;
    line-height: 1.4;
    margin-top: 10px;
  }
}

//케스케이드용
#root .input_file .input_file_in.on label {
  display: none;
}
#root
  .input_file
  .input_file_in.on
  + .input_file_in.on
  + .input_file_in.on
  + .input_file_in.on
  + .input_file_in.on
  label {
  display: inline-block;
}
