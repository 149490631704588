@import url(https://fonts.googleapis.com/earlyaccess/notosanskr.css);

/* font-family: 'Noto Sans KR', sans-serif; */

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

html {
  font-family: 'Noto Sans KR', sans-serif;
  font-weight: 300;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

body {
  line-height: 1.6;
  font-family: 'Noto Sans KR', sans-serif;
  font-weight: 300;
}

ol,
ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

hr,
legend {
  display: none;
  height: 0px;
}

a {
  text-decoration: none;
  cursor: pointer;
}

a:hover {
  text-decoration: none;
  color: #4c4c4c;
}

caption {
  font-size: 0;
  color: transparent;
  text-indent: -9999px;
  overflow: hidden;
  visibility: hidden;
  width: 0;
  height: 0;
  margin: 0;
  padding: 0;
}

label,
input,
select,
button {
  vertical-align: middle;
  font-size: 100%;
  line-height: 1.6;
  font-size: 15px;
  outline: none;
}
button {
  cursor: pointer;
  letter-spacing: -1px;
  border: 0;
}

img {
  width: auto;
  vertical-align: middle;
  border: 0;
}

em {
  font-style: normal;
}

h1,
h2,
h3,
h4,
h5 {
  margin: 0;
  padding: 0;
}

#root {
  display: flex;
  flex-flow: column;
  position: relative;
  min-height: 100vh;
  background: url('../images/home_bg.png') top center repeat;
  background-color: #f8f8f8;
}
.c-footer {
  margin-top: auto;
}

/*--posiiton--*/
.fixed-po {
  position: fixed;
}
.static-po {
  position: static;
}
.relative-po {
  position: relative;
}
.absolute-po {
  position: absolute;
}

/*--margin--*/
.mgt0 {
  margin-top: 0 !important;
}
.mgt5 {
  margin-top: 5px !important;
}
.mgt10 {
  margin-top: 10px !important;
}
.mgt15 {
  margin-top: 15px !important;
}
.mgt20 {
  margin-top: 20px !important;
}
.mgt25 {
  margin-top: 25px !important;
}
.mgt30 {
  margin-top: 30px !important;
}
.mgt35 {
  margin-top: 35px !important;
}
.mgt40 {
  margin-top: 40px !important;
}
.mgt45 {
  margin-top: 45px !important;
}
.mgt50 {
  margin-top: 50px !important;
}
.mgt60 {
  margin-top: 60px !important;
}
.mgt70 {
  margin-top: 70px !important;
}
.mgt80 {
  margin-top: 80px !important;
}

.mgl0 {
  margin-left: 0 !important;
}
.mgl5 {
  margin-left: 5px !important;
}
.mgl10 {
  margin-left: 10px !important;
}
.mgl15 {
  margin-left: 15px !important;
}
.mgl20 {
  margin-left: 20px !important;
}
.mgl25 {
  margin-left: 25px !important;
}
.mgl30 {
  margin-left: 30px !important;
}
.mgl35 {
  margin-left: 35px !important;
}
.mgl40 {
  margin-left: 40px !important;
}
.mgl45 {
  margin-left: 45px !important;
}
.mgl50 {
  margin-left: 50px !important;
}

.mgr0 {
  margin-right: 0px !important;
}
.mgr5 {
  margin-right: 5px !important;
}
.mgr10 {
  margin-right: 10px !important;
}
.mgr15 {
  margin-right: 15px !important;
}
.mgr20 {
  margin-right: 20px !important;
}
.mgr25 {
  margin-right: 25px !important;
}
.mgr30 {
  margin-right: 30px !important;
}
.mgr35 {
  margin-right: 35px !important;
}
.mgr40 {
  margin-right: 40px !important;
}
.mgr45 {
  margin-right: 45px !important;
}
.mgr50 {
  margin-right: 50px !important;
}

.mgb0 {
  margin-bottom: 0 !important;
}
.mgb5 {
  margin-bottom: 5px !important;
}
.mgb10 {
  margin-bottom: 10px !important;
}
.mgb15 {
  margin-bottom: 15px !important;
}
.mgb20 {
  margin-bottom: 20px !important;
}
.mgb25 {
  margin-bottom: 25px !important;
}
.mgb30 {
  margin-bottom: 30px !important;
}
.mgb35 {
  margin-bottom: 35px !important;
}
.mgb40 {
  margin-bottom: 40px !important;
}
.mgb45 {
  margin-bottom: 45px !important;
}
.mgb50 {
  margin-bottom: 50px !important;
}

/*--padding--*/
.pdt0 {
  padding-top: 0 !important;
}
.pdt5 {
  padding-top: 5px !important;
}
.pdt10 {
  padding-top: 10px !important;
}
.pdt15 {
  padding-top: 15px !important;
}
.pdt20 {
  padding-top: 20px !important;
}
.pdt25 {
  padding-top: 25px !important;
}
.pdt30 {
  padding-top: 30px !important;
}
.pdt35 {
  padding-top: 35px !important;
}
.pdt40 {
  padding-top: 40px !important;
}
.pdt45 {
  padding-top: 45px !important;
}
.pdt50 {
  padding-top: 50px !important;
}
.pdt60 {
  padding-top: 60px !important;
}

.pdl0 {
  padding-left: 0 !important;
}
.pdl5 {
  padding-left: 5px !important;
}
.pdl10 {
  padding-left: 10px !important;
}
.pdl15 {
  padding-left: 15px !important;
}
.pdl20 {
  padding-left: 20px !important;
}
.pdl25 {
  padding-left: 25px !important;
}
.pdl30 {
  padding-left: 30px !important;
}
.pdl35 {
  padding-left: 35px !important;
}
.pdl40 {
  padding-left: 40px !important;
}
.pdl45 {
  padding-left: 45px !important;
}
.pdl50 {
  padding-left: 50px !important;
}

.pdr0 {
  padding-right: 0px !important;
}
.pdr5 {
  padding-right: 5px !important;
}
.pdr10 {
  padding-right: 10px !important;
}
.pdr15 {
  padding-right: 15px !important;
}
.pdr20 {
  padding-right: 20px !important;
}
.pdr25 {
  padding-right: 25px !important;
}
.pdr30 {
  padding-right: 30px !important;
}
.pdr35 {
  padding-right: 35px !important;
}
.pdr40 {
  padding-right: 40px !important;
}
.pdr45 {
  padding-right: 45px !important;
}
.pdr50 {
  padding-right: 50px !important;
}

.pdb0 {
  padding-bottom: 0 !important;
}
.pdb5 {
  padding-bottom: 5px !important;
}
.pdb10 {
  padding-bottom: 10px !important;
}
.pdb15 {
  padding-bottom: 15px !important;
}
.pdb20 {
  padding-bottom: 20px !important;
}
.pdb25 {
  padding-bottom: 25px !important;
}
.pdb30 {
  padding-bottom: 30px !important;
}
.pdb35 {
  padding-bottom: 35px !important;
}
.pdb40 {
  padding-bottom: 40px !important;
}
.pdb45 {
  padding-bottom: 45px !important;
}
.pdb50 {
  padding-bottom: 50px !important;
}

/*--align--*/
.txtc {
  text-align: center !important;
}
.txtl {
  text-align: left !important;
}
.txtr {
  text-align: right !important;
}
.vert {
  vertical-align: top !important;
}

/*--border-none--*/
.brtn {
  border-top: none !important;
}
.brln {
  border-left: none !important;
}
.brrn {
  border-right: none !important;
}
.brbn {
  border-bottom: none !important;
}

.col-cont-btn.type-02 {
  min-width: 170px;
}

/*셀렉트*/
select::-ms-expand {
  display: none;
}
select {
  display: inline-block;
  vertical-align: middle;
  padding: 0 35px 0 15px;
  height: 70px;
  line-height: 70px;
  font-size: 15px;
  background-color: #fff;
  box-sizing: border-box;
  -webkit-appearance: none;
  border-radius: 4px;
  border: 0;
  background: url('../images/Icon-toggle.png') no-repeat right 15px center rgba(219, 219, 219, 0.76);
}

/*인풋*/
.select-input-w {
  font-size: 0;
  select {
    width: 140px;
    margin-right: 10px;
    vertical-align: top;
  }
  .select-container {
    width: 140px;
    margin-right: 10px;
    vertical-align: top;
  }
  .input_t {
    width: 335px;
    display: inline-block;
    vertical-align: middle;
  }
  .close {
    display: inline-block;
    vertical-align: middle;
    width: 20px;
    height: 20px;
    font: 0/0 a;
    background: url('../images/close-icon.png') no-repeat 0 0;
    margin-left: 20px;
  }
}
.err {
  text-align: left;
  font-size: 14px;
  line-height: 1.4;
  margin-top: 3px;
  color: #d03739;
  font-weight: 400;
}
