/* 05-List 테이블 부분 */
.col-cont-table-box {
  text-align: center;
  font-family: 'RixSGoB', sans-serif;
  font-size: 14px;
  width: 877px;
}
.col-cont-table-box table {
  border-spacing: 0;
  border-collapse: collapse;
  width: 100%;
}
.col-cont-table-box .col-cont-tit {
  font-size: 20px;
}
.col-cont-table-box thead th {
  border-left: 2px solid transparent;
  width: 14%;
  vertical-align: middle;
}
.col-cont-table-box thead th:first-child {
  border: 0 none;
}
.col-cont-table-box thead th .cont-table-txt-wrap {
  background: #b0b0b0;
  color: #fff;
}
.col-cont-table-box tbody td {
  vertical-align: middle;
  color: #575757;
  border-left: 2px solid transparent;
  border-top: 2px solid transparent;
}
.col-cont-table-box tbody td:first-child {
  border: 0 none;
}
.col-cont-table-box tbody td:nth-child(2) .cont-table-txt-wrap {
  padding: 0 5px;
}
.col-cont-table-box tbody td .cont-table-txt-wrap {
  border: 1px solid #b0b0b0;
  box-sizing: border-box;
}
.col-cont-table-box tbody td .cont-table-txt-wrap.error {
  color: #d03739;
}
.col-cont-table-box .cont-table-icon-hover {
  cursor: pointer;
  line-height: 18px;
}
.col-cont-table-box .cont-table-icon-hover:hover svg rect {
  fill: #6d6d6d;
}
.col-cont-table-box .cont-table-icon-hover .tooltip_hover {
  display: none;
}
.col-cont-table-box .cont-table-icon-hover:hover .tooltip_hover {
  display: block;
  position: absolute;
  top: -50%;
  left: 50%;
  padding: 10px 15px;
  border-radius: 4px;
  border: 1px solid rgba(109, 109, 109, 0.8);
  color: #6d6d6d;
  font-size: 14px;
  transform: translate(-50%, -120%);
  min-width: 315px;
  z-index: 5;
  cursor: initial;
  background: #f0f0f0;
  line-height: 1.4;
  height: auto;
}
.col-cont-table-box .cont-table-icon-hover:hover .tooltip_hover:after {
  display: block;
  content: '';
  position: absolute;
  bottom: -7px;
  left: 50%;
  border-width: 1px;
  border-style: solid;
  border-color: transparent rgb(109, 109, 109) rgb(109, 109, 109) transparent;
  transform: rotate(-315deg);
  width: 10px;
  height: 10px;
  margin-left: -5px;
  background: #f0f0f0;
}
.col-cont-table-box i {
  vertical-align: middle;
  position: relative;
  top: 2px;
}
.col-cont-table-box div {
  position: relative;
  line-height: 35px;
}
.col-cont-table-box tbody td:last-child .cont-table-txt-wrap {
  border: 0 none;
}
.col-cont-table-box .cont-table-txt-wrap .col-cont-btn {
  border: 0 none;
  padding: 0;
  vertical-align: top;
  line-height: 35px;
  width: 100%;
  border-radius: 0;
  min-width: 115px;
  font-size: 13px;
  pointer-events: none;
}
.col-cont-table-box .cont-table-txt-wrap .col-cont-btn.on {
  pointer-events: auto;
}
