.pay-wrap {
  margin-bottom: 60px;
  border: 1px solid #b0b0b0;
  box-sizing: border-box;
  width: 790px;
  > .react-tabs {
    > .react-tabs__tab-list {
      display: flex;
      li {
        width: 33%;
        text-align: center;
        border-bottom: 1px solid #b0b0b0;
        height: 60px;
        line-height: 60px;
        cursor: pointer;
        box-sizing: border-box;
        span {
          font-size: 18px;
          font-family: 'RixSGoB', sans-serif;
          line-height: 1.2;
          text-align: center;
          color: #9e9e9e;
        }
      }
      li + li {
        width: 34%;
        border-left: 1px solid #b0b0b0;
      }
      .react-tabs__tab--selected {
        background-color: #b0b0b0;
        span {
          color: #fff;
        }
      }
    }
  }
  & .pay-in {
    padding: 30px 90px;
    text-align: left;
    .radio-w {
      font-size: 0;
      .input_r {
        margin-right: 35px;
        min-width: 83px;
        margin-bottom: 13px;
      }
    }
    strong {
      font-size: 16px;
      font-family: 'RixSGoB', sans-serif;
      line-height: 1.2;
      color: #111;
      display: block;
      letter-spacing: -1px;
    }
    .react-tabs__tab-list {
      font-size: 0;
      li {
        display: inline-block;
        margin-right: 25px;
        margin-bottom: 13px;
      }
    }
    .guide {
      font-size: 14px;
      font-family: 'RixSGoB', sans-serif;
      line-height: 1.4;
      color: #838383;
      letter-spacing: -1px;
      margin: 7px 0 0;
    }
  }
}
