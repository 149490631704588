.app-version-container {
  position: absolute;
  z-index: 4;
  left: 46px;
  bottom: 1em;

  .app-version {
    font-size: 0.7em;
    color: #6c6c6c;
    letter-spacing: 0.1em;

    &:before {
      content: 'v';
    }
  }
}
