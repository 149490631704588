#pageloading {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: #fff;
  opacity: 0.5;
  z-index: 9999998;
  .pageloading {
    position: fixed;
    top: 50%;
    width: 100%;
    text-align: center;
  }
}
