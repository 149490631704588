.home-Wrap {
  max-width: 1500px;
  margin: 50px auto 25px;
  .home-Top {
    h2 {
      font-weight: 600;
      font-size: 48px;
      line-height: 57px;
      text-align: center;
      color: #000000;
      letter-spacing: -1px;
    }
    strong {
      display: block;
      font-weight: 600;
      font-size: 20px;
      line-height: 28px;
      text-align: center;
      color: #222325;
      margin-top: 13px;
      letter-spacing: -1px;
    }
    p {
      font-size: 14px;
      line-height: 20px;
      text-align: center;
      letter-spacing: -1px;
      color: #838383;
      margin: 20px 0 0;
    }
    .home-none {
      margin: 170px 0;
      p {
        margin-top: 10px;
      }
      + button {
        margin-bottom: 100px;
      }
    }
  }
  .home-list {
    width: 600px;
    margin: 34px auto 0;
    letter-spacing: -1px;
    h3 {
      color: #222325;
      margin: 0 auto;
      font-weight: 600;
      font-size: 20px;
      line-height: 28px;
      text-align: center;
    }
    ul {
      li {
        margin-top: 10px;
        text-align: left;
      }
      .list-in {
        padding: 8px;
        border-radius: 4px;
        background: rgba(219, 219, 219, 0.76);
        .list-top {
          font-weight: 400;
          color: #525252;
          font-size: 16px;
          line-height: 20px;
          padding: 10px 40px 10px 10px;
          position: relative;
          cursor: pointer;
          &:before {
            display: block;
            content: '';
            background: url('../../images/Icon-toggle.png') no-repeat 0 0;
            width: 12px;
            height: 7px;
            position: absolute;
            top: 50%;
            margin-top: -3.5px;
            right: 10px;
            transform: rotate(0deg);
            transition: transform 0.5s;
          }
          &.on:before {
            transform: rotate(180deg);
          }
        }
        .list-cont {
          color: #828282;
          font-size: 16px;
          line-height: 20px;
          padding: 15px 10px 15px 10px;
          background-color: #fff;
          margin-top: 10px;
          display: none;
        }
      }
    }
  }
  button {
    display: block;
    font-family: 'Noto Sans KR', sans-serif;
    border: 0;
    margin: 20px auto 0;
    cursor: pointer;
    border-radius: 4px;
    font-size: 16px;
    text-align: center;
    font-weight: 400;
    &.red {
      width: 152px;
      background: #d03739;
      color: #f9f9f9;
    }
    &.all {
      color: #575757;
      border: 1px solid rgba(219, 219, 219, 0.76);
      background-color: transparent;
      width: 100%;
      height: 56px;
      line-height: 56px;
      box-sizing: content-box;
      margin-top: 10px;
      padding: 0;
      a {
        display: block;
        color: #575757;
      }
    }
  }
}
