body {
  min-width: 1300px;
}
.clear_both:after {
  content: '';
  display: block;
  clear: both;
}

.wrap-lolshop__pc_img {
  display: block !important;
}
.wrap-lolshop__mo_img {
  display: none !important;
}

.default_top_nav {
  position: relative;
  background-color: #000;
  width: 100%;
  height: 120px;
}
.default_top_nav .nav_logo_wrap {
  height: 120px;
}
.default_top_nav .default_nav_logo {
  display: inline-block;
  margin: 41px 0 0 46px;
}
.default_top_nav .default_menu_wrap {
  position: absolute;
  top: 0;
  left: 203px;
  display: table;
  max-width: 100%;
  height: 120px;
  text-align: center;
  letter-spacing: -1px;
  font-size: 0;
}
.default_top_nav .default_menu_wrap .default_menu_link {
  margin: 0 40px;
}
.default_top_nav .default_menu_wrap .default_menu_link:first-child {
  margin-left: 0;
}
.default_top_nav .default_menu_wrap .default_menu_link:last-child {
  margin-right: 0;
}
.default_top_nav .default_menu_link {
  display: inline-block;
  line-height: 118px;
  padding: 2px 0;
  color: #fff;
  font-weight: 800;
  font-size: 16px;
}
.default_top_nav .default_menu_link:hover,
.default_top_nav .default_menu_link.on {
  color: #d13639;
}
.default_top_nav .default_lang_wrap {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  padding: 0 30px 0 0;
  height: 120px;
  font-size: 0;
  display: flex;
  align-items: center;
  text-align: center;
}
.default_top_nav .default_lang_wrap .default_menu_btn {
  color: #fff;
  font-weight: 800;
  font-style: normal;
  font-size: 18px;
  position: relative;
  background: #0bc4e2;
  border-radius: 6px;
  padding: 20px;
  display: flex;
  align-items: center;
  text-align: center;
}

.col-wrap {
  position: relative;
  min-height: 805px;
  min-height: calc(100vh - 430px);
  display: flex;
  flex-flow: column;
  text-align: center;
  align-items: center;
  justify-content: center;
}
.col-wrap .inner {
  max-width: 1500px;
  margin: 0 auto;
  padding: 55px 0;
}
.col-wrap .inner.ty2 {
  padding: 110px 0;
}

/* 공통 푸터 */
.riotbar-footer-content {
  padding: 0;
}
.riotbar-footer-content.default-background {
  display: flex;
  flex-flow: row wrap;
  border: 0 none;
}
.riotbar-footer-riot-logo {
  order: 1;
  flex: 1 50%;
  max-width: 242px;
  min-height: 152px;
  text-align: center;
  position: relative;
}
.riotbar-footer-riot-logo a {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.riotbar-copyright {
  text-align: left;
  font-size: 14px;
  order: 2;
  flex: 1 50%;
  max-width: 100%;
  margin: 0;
  padding-top: 50px;
}
.riotbar-copyright.riotbar-copyright-type-02 {
  order: 3;
  flex: 1 50%;
  margin: 0 0 0 46px;
  padding: 34px 0 56px 0;
  border-top: 1px solid #2b2b2b;
}
.riotbar-social-links {
  display: flex;
  justify-content: flex-end;
  margin: 0 48px 0 0;
  flex: 1 0 0;
  order: 4;
  padding: 39px 0 55px 0;
  border-top: 1px solid #2b2b2b;
}
.riotbar-social-links ul li a.link-social svg {
  width: 30px;
  height: 30px;
}

/* 공통 타이틀 및 텍스트 영역 */
.col-cont-tit-box {
  text-align: center;
  margin-bottom: 60px;
  font-family: 'RixSGoB', sans-serif;
}
.col-cont-tit {
  font-size: 36px;
  font-weight: 800;
  color: #000;
  margin-bottom: 10px;
  line-height: 1.3;
}
.col-cont-tit.type-name {
  color: rgba(0, 0, 0, 0.4);
}
.col-cont-tit.type-name.on {
  color: #000;
}
.col-cont-tit.type-small {
  font-size: 20px;
}
.col-cont-tit.type-middle {
  font-size: 24px;
}
.cont-table-icon-hover.single {
  cursor: pointer;
  line-height: 18px;
  position: relative;
  display: inline-block;
  vertical-align: middle;
}
.cont-table-icon-hover.single:hover svg rect {
  fill: #6d6d6d;
}
.cont-table-icon-hover.single .tooltip_hover {
  display: none;
}
.cont-table-icon-hover.single:hover .tooltip_hover {
  display: block;
  position: absolute;
  top: -20%;
  left: 50%;
  padding: 10px 15px;
  border-radius: 4px;
  border: 1px solid rgba(109, 109, 109, 0.8);
  color: #6d6d6d;
  font-size: 14px;
  transform: translate(-50%, -120%);
  min-width: 315px;
  z-index: 5;
  cursor: initial;
  background: #f0f0f0;
  line-height: 1.4;
  height: auto;
}
.cont-table-icon-hover.single:hover .tooltip_hover:after {
  display: block;
  content: '';
  position: absolute;
  bottom: -7px;
  left: 50%;
  border-width: 1px;
  border-style: solid;
  border-color: transparent rgb(109, 109, 109) rgb(109, 109, 109) transparent;
  transform: rotate(-315deg);
  width: 10px;
  height: 10px;
  margin-left: -5px;
  background: #f0f0f0;
}
.col-cont-txt {
  font-size: 14px;
  font-family: 'RixSGoB', sans-serif;
  line-height: 20px;
  color: #838383;
  margin-bottom: 20px;
}
.col-cont-tit-box .col-cont-txt {
  letter-spacing: -0.5px;
}
.col-cont-txt.bold {
  font-size: 24px;
  color: #000;
  line-height: 1.3;
}
.col-cont-txt.symbol {
  font-size: 300px;
  color: rgba(0, 0, 0, 0.1);
  line-height: 1;
  font-family: 'Noto Sans KR', sans-serif;
  font-weight: bold;
}
.col-cont-txt-w {
  display: inline-block;
  text-align: left;
  margin-top: 35px;
}

/* 3-Categorize transaction 인증리스트 */
.col-cont-item-list-box {
  display: flex;
  justify-content: center;
  margin-bottom: 60px;
}
.col-cont-item-list-box li {
  text-align: center;
  width: 265px;
  min-height: 320px;
  background: #fff;
  border-radius: 6px;
  margin-left: 20px;
}
.col-cont-item-list-box li:first-child {
  margin-left: 0;
}
.col-cont-item-list-box li a {
  display: block;
  height: 100%;
  padding: 15px 0 0 0;
}
.col-cont-item-list-box li i {
  display: inline-block;
  min-width: 125px;
  min-height: 125px;
  margin-bottom: 20px;
}
.col-cont-item-list-box li p em {
  font-size: 20px;
  color: #141414;
}
.col-cont-item-list-box li p {
  font-size: 14px;
  line-height: 24px;
  color: #838383;
  font-family: 'RixSGoB', sans-serif;
  margin-bottom: 20px;
}
.col-cont-item-list-box li p + p {
  line-height: 20px;
}

/* 공통버튼 */
.col-cont-btn-box {
  text-align: center;
}
.col-cont-btn {
  display: inline-block;
  background: transparent;
  border: 2px solid #b0b0b0;
  box-sizing: border-box;
  border-radius: 4px;
  color: #575757;
  padding: 10px 15px;
  cursor: pointer;
  min-width: 170px;
}
.col-cont-btn.type-02 {
  background: #d03739;
  border: 2px solid #d03739;
  color: #fff;
  opacity: 0.5;
  pointer-events: none;
}
.col-cont-btn.type-02.on {
  opacity: 1;
  pointer-events: auto;
}
.col-cont-btn-box.col-cont-btn-box-column {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;
}
.col-cont-btn-box.col-cont-btn-box-column > .col-cont-btn:last-child {
  margin-top: 10px;
}
.col-cont-btn.agree:before {
  display: inline-block;
  content: '';
  width: 20px;
  height: 17px;
  background: url('../images/chk_off_icon.png') no-repeat center center;
  vertical-align: middle;
  margin-right: 5px;
  margin-bottom: 1px;
}
.col-cont-btn.agree:active:before,
.col-cont-btn.agree.on:before {
  background-image: url('../images/chk_on_icon.png');
}
.col-cont-btn.small {
  padding: 5px 10px;
}

/* 05-List 본인인증 부분 */
.col-cont-certify-box {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 60px;
}
.col-cont-certify-box .col-cont-tit {
  font-size: 20px;
  margin: 0;
}
.col-cont-certify-box .col-cont-txt {
  margin: 0;
}

/* 08-기타미납 개인정보수집동의 */
.col-cont-agree-box {
  background: #fff;
  max-width: 1000px;
  margin: 0 auto 50px;
  font-size: 16px;
  text-align: left;
  padding: 45px 40px;
  font-family: 'RixSGoB', sans-serif;
  box-sizing: border-box;
  letter-spacing: -0.5px;
}
.col-cont-agree-box .col-cont-agree-tit {
  color: #403f3f;
}
.col-cont-agree-box .col-cont-agree-txt {
  color: #838383;
  margin-top: 25px;
}
.col-cont-agree-box .col-cont-table-box {
  width: 50%;
  margin-top: 25px;
}
.col-cont-agree-box .col-cont-table-box td:last-child .cont-table-txt-wrap {
  border: 1px solid #b0b0b0;
  box-sizing: border-box;
}
.col-cont-agree-box .col-cont-table-box p {
  text-align: left;
  color: #838383;
  font-size: 16px;
}
.col-cont-agree-box .col-cont-agree-txt button {
  color: #0359ff;
  background-color: transparent;
  text-decoration: underline;
  padding: 0;
  display: inline-block;
}
.col-cont-agree-box .col-cont-agree-txt button + button {
  margin-left: 5px;
}
