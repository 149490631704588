/*리액트-셀렉트 커스텀*/
.select-container {
  display: inline-block;
  vertical-align: middle;
  .select__control {
    display: block;
    padding: 0 40px 0 15px;
    height: 70px;
    line-height: 70px;
    font-size: 15px;
    background-color: rgba(219, 219, 219, 0.76);
    box-sizing: border-box;
    -webkit-appearance: none;
    border-radius: 4px;
    border: none;
    box-shadow: none;
    overflow: hidden;
    cursor: pointer;
    .select__value-container {
      display: block;
      padding: 0;
      .select__single-value {
        position: static;
        display: block;
        transform: translateY(0%);
        margin: 0;
        max-width: 100%;
        z-index: 1;
      }
      .select__input {
        position: absolute;
        top: -50px;
        left: 0;
        //display: none !important;
      }
    }
    .select__indicators {
      position: absolute;
      top: 50%;
      right: 10px;
      margin-top: -10px;
      .select__indicator {
        svg {
          display: none;
        }
        transform: rotate(0deg);
        transition: transform 0.5s;
        width: 20px;
        height: 20px;
        background: url('../../../../assets/images/Icon-toggle.png') no-repeat center center;
      }
      .select__indicator-separator {
        display: none;
      }
    }
    &.select__control--menu-is-open {
      .select__indicator {
        transform: rotate(180deg);
      }
    }
  }
  .select__menu {
    font-size: 15px;
    margin: 0;
    border-radius: 0;
    background-color: #ababab;
    z-index: 2;
    color: #fff;
    .select__menu-list {
      padding: 0;
      .select__option {
        padding: 12px;
        cursor: pointer;
        &.select__option--is-selected {
          background-color: #868686 !important;
        }
        &.select__option--is-focused {
          background-color: #ababab;
        }
        &:hover {
          background-color: #868686;
        }
      }
    }
  }
}
