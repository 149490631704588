@charset "utf-8";
/* @import url(https://fonts.googleapis.com/earlyaccess/notosanskr.css); */
 @font-face {
    font-family: "RixSGoB";
    src: url("../fonts/RixSGoB.eot");
    src: url("../fonts/RixSGoB.eot?#iefix") format("embedded-opentype"), url("../fonts/RixSGoB.woff") format("woff"), url("../fonts/RixSGoB.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
}

/*@font-face {
    font-family: "SCDream2";
    src: url("../fonts/SCDream2.eot");
    src: url("../fonts/SCDream2.eot?#iefix") format("embedded-opentype"), url("../fonts/SCDream2.woff") format("woff"), url("../fonts/SCDream2.ttf") format("truetype");
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: "SCDream3";
    src: url("../fonts/SCDream3.eot");
    src: url("../fonts/SCDream3.eot?#iefix") format("embedded-opentype"), url("../fonts/SCDream3.woff") format("woff"), url("../fonts/SCDream3.ttf") format("truetype");
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: "SCDream4";
    src: url("../fonts/SCDream4.eot");
    src: url("../fonts/SCDream4.eot?#iefix") format("embedded-opentype"), url("../fonts/SCDream4.woff") format("woff"), url("../fonts/SCDream4.ttf") format("truetype");
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: "SCDream5";
    src: url("../fonts/SCDream5.eot");
    src: url("../fonts/SCDream5.eot?#iefix") format("embedded-opentype"), url("../fonts/SCDream5.woff") format("woff"), url("../fonts/SCDream5.ttf") format("truetype");
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: "SCDream6";
    src: url("../fonts/SCDream6.eot");
    src: url("../fonts/SCDream6.eot?#iefix") format("embedded-opentype"), url("../fonts/SCDream6.woff") format("woff"), url("../fonts/SCDream6.ttf") format("truetype");
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: "SCDream7";
    src: url("../fonts/SCDream7.eot");
    src: url("../fonts/SCDream7.eot?#iefix") format("embedded-opentype"), url("../fonts/SCDream7.woff") format("woff"), url("../fonts/SCDream7.ttf") format("truetype");
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: "SCDream8";
    src: url("../fonts/SCDream8.eot");
    src: url("../fonts/SCDream8.eot?#iefix") format("embedded-opentype"), url("../fonts/SCDream8.woff") format("woff"), url("../fonts/SCDream8.ttf") format("truetype");
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: "SCDream9";
    src: url("../fonts/SCDream9.eot");
    src: url("../fonts/SCDream9.eot?#iefix") format("embedded-opentype"), url("../fonts/SCDream9.woff") format("woff"), url("../fonts/SCDream9.ttf") format("truetype");
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: "TmonMonsori";
    src: url("../fonts/TmonMonsori.eot");
    src: url("../fonts/TmonMonsori.eot?#iefix") format("embedded-opentype"), url("../fonts/TmonMonsori.woff") format("woff"), url("../fonts/TmonMonsori.ttf") format("truetype");
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: "UnitedSansCond-Light";
    src: url("../font/UnitedSansCond-Light.eot");
    src: url("../font/UnitedSansCond-Light.eot?#iefix") format("embedded-opentype"), url("../font/UnitedSansCond-Light.woff") format("woff"), url("../font/UnitedSansCond-Light.ttf") format("truetype");
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: "UnitedSansCond-Bold";
    src: url("../font/UnitedSansCond-Bold.eot");
    src: url("../font/UnitedSansCond-Bold.eot?#iefix") format("embedded-opentype"), url("../font/UnitedSansCond-Bold.woff") format("woff"), url("../font/UnitedSansCond-Bold.ttf") format("truetype");
    font-weight: 600;
    font-style: normal;
} */

.blind {
    position: absolute;
    top: -9999px;
    font-size: 0;
}


/*** Footer ***/

/* #footer {
    width: 100%;
    margin: 0 auto;
    padding: 0 0 40px 0;
    text-align: center;
}

.simple-menu {
    margin: 0 0 30px 0;
}

.simple-menu span {
    margin: 0 18px 0 0;
    padding: 0 0 0 22px;
    display: inline-block;
    border-left: solid 1px #bafff7;
    line-height: 12px;
}

.simple-menu span:first-child {
    padding: 0;
    border: none;
}

.simple-menu span a {
    color: #bafff7;
}

.default-3-5 .simple-menu {
    margin: 0 0 25px 0;
}

.default-3-5 .simple-menu span {
    margin: 0 14px 0 0;
    padding: 0 0 0 17px;
    border-left: solid 1px #808080;
}

.default-3-5 .simple-menu span:first-child,
span:nth-child(5) {
    padding: 0;
    border: none;
}

.default-3-5 .simple-menu span:nth-child(8) {
    padding: 0;
    border: none;
} */


/* 부모전용고객센터 두번째줄 좌측 라인 제거 */

/* .default-3-5 .simple-menu span a {
    color: #808080;
    font-size: 13px;
}

.default-3-5 .simple-menu span:first-child+span a {
    color: #a1a1a1;
    font-weight: bold;
}

#footer .gs-container {
    position: relative;
    margin: 0 !important;
}

#footer #riot-social {
    position: absolute;
    left: 8px;
    top: -13px;
    padding: 20px 19px 0 16px;
    width: 103px;
    height: 120px;
    background: url('http://static.leagueoflegends.co.kr/common/img/common/common.png') no-repeat -470px top;
}

#footer #riot-social .riot-logo {
    margin: 0 0 10px 0;
    display: block;
    height: 78px;
    font-size: 0;
    text-indent: -9999px;
}

#footer #riot-social .social-links {
    overflow: hidden;
}

#footer #riot-social .social-links li {
    float: left;
    width: 100%;
    text-align: center;
    padding: 6px 0 0 0;
    height: 28px;
}

#footer #riot-social .social-links li a {
    display: inline-block;
    vertical-align: top;
    width: 60px;
    height: 26px;
    font-size: 0;
    text-indent: -9999px;
}

#footer #riot-social.new-social {
    background: none;
}

#footer #riot-social.new-social .riot-logo {
    background: url('http://static.leagueoflegends.co.kr/common/img/common/common.png') no-repeat -485px -23px;
}

#footer #riot-social.new-social .social-links>li {
    width: 48px;
    height: 51px;
}

#footer #riot-social.new-social .social-links>li a {
    background: url('http://static.leagueoflegends.co.kr/common/img/common/common_new.png') no-repeat;
    width: 48px;
    height: 51px;
}

#footer #riot-social.new-social .social-links>li.youtube a {
    background-position: -19px -112px;
}

#footer #riot-social.new-social .social-links>li.facebook a {
    background-position: -69px -112px;
}

#footer .default-3-5 {
    width: 600px;
    margin: 0 auto;
}

#footer .default-3-5 #footer-play-for-free {
    margin: 0 0 29px 0;
    color: #36332d;
    width: 162px;
}

#footer .default-3-5 .innerTxt {
    color: #5e5e5e;
    font-size: 11px;
}

#footer .default-3-5 .innerTxt a {
    color: #5e5e5e;
    font-size: 11px;
    text-decoration: underline;
}

#footer .default-3-5 .innerTxt li {
    margin: 0 0 1px 0;
}

#footer .default-3-5 .innerTxt li.game-info {}

#footer .default-3-5 .innerTxt li div {
    margin: 28px auto 24px 71px;
    width: 454px;
    height: 64px;
    background: url('http://static.leagueoflegends.co.kr/common/img/common/game-grade.png') no-repeat left top;
    text-indent: -9999px;
    overflow: hidden;
    white-space: nowrap;
}

#footer .default-3-5 .innerTxt li.end {
    color: #191919;
}

#footer #pvp-net-ad {
    position: absolute;
    right: 25px;
    top: 50px;
}

#footer #pvp-net-ad .pvp-net {
    margin: 0 0 13px 0;
    display: block;
    width: 169px;
    height: 26px;
    font-size: 0;
    text-indent: -9999px;
    background: url('http://static.leagueoflegends.co.kr/common/img/common/common.png') no-repeat -120px top;
}

#footer #pvp-net-ad .simple-menu {
    margin: 0;
    text-align: left;
}

#footer #pvp-net-ad .simple-menu span {
    margin: 0 8px 0 0;
    padding: 0 0 0 12px;
    border-left: solid 1px #656565;
    line-height: 10px;
}

#footer #pvp-net-ad .simple-menu span:first-child {
    padding: 0;
    border: none;
}

#footer #pvp-net-ad .simple-menu span a {
    color: #656565;
    font-size: 11px;
    line-height: 12px;
}

#footer .default-3-5 {
    float: none;
    padding: 0;
}

#footer.event-footer {
    padding-top: 30px;
}

#footer.event-footer .gameInfo {
    position: relative;
}

#footer.event-footer .default-3-5 .innerTxt li div {
    margin: 28px auto 24px 40px
}

#footer.event-footer .gameInfo .riot-logo {
    position: absolute;
    right: 39px;
    top: 0;
    display: block;
    width: 52px;
    height: 64px;
    background: url('http://static.leagueoflegends.co.kr/common/img/common/riot_logo.gif') 0 0 no-repeat;
    font: 0/0 a;
    zoom: 1;
} */

/* .c-footer */
.c-footer {
    position: relative;
    z-index: 3;
}

.riotbar-footer {
    user-select: none;
    box-sizing: border-box;
}

.riotbar-footer * {
    box-sizing: border-box;
}

.riotbar-footer {
    text-align: center;
}

.riotbar-footer:lang(ar) {
    direction: rtl;
}

.riotbar-footer-content {
    padding: 1px 5px;
}

.riotbar-footer-content.default-background {
    background-color: #111111;
    border-top: 2px solid rgba(51, 51, 51, 0.25);
}

/* .riotbar-footer-content>div {
    margin: 40px auto;
} */

.riotbar-footer-links ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
}

.riotbar-footer-links {
    user-select: none;
    box-sizing: border-box;
}

.riotbar-footer-links * {
    box-sizing: border-box;
}

/* Breakpoints */
/* Common Mixins */
.riotbar-footer-links {
    color: #7E7E7E;
    /* These custom font references are actually loaded in when the `customFonts` plugin is enabled */
    /* Main font by default */
    font-family: "FF Mark W05";
    font-weight: 800;
    font-size: 11px;
    letter-spacing: 0.1em;
    text-align: center;
    text-transform: uppercase;
}

.riotbar-footer-links:lang(ar) {
    font-family: "Cairo", Tahoma, sans-serif;
}

.riotbar-footer-links:lang(ru) {
    font-family: "Neue Frutiger World W05", Tahoma, sans-serif;
}

.riotbar-footer-links:lang(ko),
.riotbar-footer-links:lang(kr) {
    font-family: "RixSGo", Tahoma, sans-serif;
}

.riotbar-footer-links:lang(ar) {
    letter-spacing: 0;
}

.riotbar-footer-links ul li a {
    display: block;
    margin: 0 16px;
    padding: 0px;
    text-decoration: none;
    color: #7E7E7E;
    transition: all 0.2s linear;
}

.riotbar-footer-links ul li a:visited {
    color: #7E7E7E;
}

.riotbar-footer-links ul li a:hover,
.riotbar-footer-links ul li a:active,
.riotbar-footer-links ul li a:focus {
    color: #F9F9F9;
}

@media (max-width: 640px) {
    .riotbar-footer-links ul {
        flex-direction: column;
    }

    .riotbar-footer-links ul li a {
        margin: 16px 0;
    }

    .riotbar-footer-links ul li:first-child a {
        margin-top: 0;
    }

    .riotbar-footer-links ul li:last-child a {
        margin-bottom: 0;
    }
}

.riotbar-footer-riot-logo {
    user-select: none;
    box-sizing: border-box;
}

.riotbar-footer-riot-logo * {
    box-sizing: border-box;
}

.riotbar-footer-riot-logo a .image-alt-text {
    opacity: 0;
    user-select: none;
    color: transparent;
    text-decoration: none;
}

.riotbar-footer-riot-logo a {
    display: inline-block;
    margin: auto;
    overflow: hidden;
    opacity: 0.9;
    transition: opacity 0.2s linear;
}

.riotbar-footer-riot-logo a:hover,
.riotbar-footer-riot-logo a:active,
.riotbar-footer-riot-logo a:focus {
    opacity: 1;
}

.riotbar-footer-riot-locale {
    user-select: none;
    box-sizing: border-box;
}

.riotbar-footer-riot-locale * {
    box-sizing: border-box;
}

/* Breakpoints */
/* Common Mixins */
.riotbar-footer-riot-locale {
    /* These custom font references are actually loaded in when the `customFonts` plugin is enabled */
    /* Main font by default */
    font-family: "FF Mark W05";
    font-weight: 500;
    z-index: 100;
    position: relative;
    color: #F9F9F9;
    width: 600px;
}

.riotbar-footer-riot-locale button {
    outline: 0;
}

.riotbar-footer-riot-locale .localization-management-list ul {
    background-color: #F9F9F9;
}

.riotbar-footer-riot-locale .localization-management-list ul li a,
.riotbar-footer-riot-locale .localization-management-list ul li a:visited,
.riotbar-footer-riot-locale .localization-management-list ul li a:link {
    color: #7E7E7E;
}

.riotbar-footer-riot-locale .localization-management-list ul li:hover a {
    color: #333333;
}

.riotbar-footer-riot-locale .localization-management-list ul li.active {
    cursor: default;
}

.riotbar-footer-riot-locale:lang(ar) {
    font-family: "Cairo", Tahoma, sans-serif;
}

.riotbar-footer-riot-locale:lang(ru) {
    font-family: "Neue Frutiger World W05", Tahoma, sans-serif;
}

.riotbar-footer-riot-locale:lang(ko),
.riotbar-footer-riot-locale:lang(kr) {
    font-family: "RixSGo", Tahoma, sans-serif;
}

@media (max-width: 1024px) {
    .riotbar-footer-riot-locale #footer-region-switcher-activate {
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 10px;
    }

    .riotbar-footer-riot-locale .second.region-chooser-button-holder {
        display: block;
        margin: 0 !important;
    }

    .riotbar-footer-riot-locale .region-chooser-button-holder .region-select-list {
        left: 180px;
    }
}

.riotbar-footer-riot-locale .region-chooser-button {
    letter-spacing: 0;
    outline: 0;
    line-height: 48px;
}

.riotbar-footer-riot-locale .region-chooser-button svg {
    margin-top: 20px;
}

.riotbar-footer-riot-locale .region-chooser-button-holder {
    position: relative;
    display: inline-block;
}

.riotbar-footer-riot-locale .region-chooser-button-holder.second {
    margin: 0 0 0 8px;
}

.riotbar-footer-riot-locale .region-chooser-button-holder.second:lang(ar) {
    margin: 0 8px 0 0;
}

.riotbar-footer-riot-locale .region-chooser-button-holder .region-select-list {
    position: absolute;
    top: 0;
    left: 0;
    border: 1px solid #F9F9F9;
    border-top: 0;
    border-radius: 5px;
    width: 100%;
    background-color: #F9F9F9;
}

.riotbar-footer-riot-locale .region-chooser-button-holder .region-select-list .active {
    display: none;
}

.riotbar-footer-riot-locale .region-chooser-button-holder .region-select-list div.current-region,
.riotbar-footer-riot-locale .region-chooser-button-holder .region-select-list div.current-language {
    height: 48px;
    line-height: 48px;
    padding: 0 16px;
    font-weight: 800;
    font-size: 11px;
    color: #999999;
    text-align: left;
}

.riotbar-footer-riot-locale .region-chooser-button-holder .region-select-list div.current-region:lang(ar),
.riotbar-footer-riot-locale .region-chooser-button-holder .region-select-list div.current-language:lang(ar) {
    text-align: right;
}

.riotbar-footer-riot-locale .region-chooser-button-holder .region-select-list div.current-region svg,
.riotbar-footer-riot-locale .region-chooser-button-holder .region-select-list div.current-language svg {
    margin-top: 20px;
    float: right;
    transform: rotate(180deg);
}

.riotbar-footer-riot-locale .region-chooser-button-holder .region-select-list div.current-region svg:lang(ar),
.riotbar-footer-riot-locale .region-chooser-button-holder .region-select-list div.current-language svg:lang(ar) {
    float: left;
}

.riotbar-footer-riot-locale.active .riotbar-footer-region-changer {
    visibility: visible;
    opacity: 1;
    transform: scale(1);
}

.riotbar-footer-riot-locale .riotbar-footer-region-changer {
    visibility: hidden;
    opacity: 0;
    transform: scale(0);
    transition: transform 0.5s cubic-bezier(0.06, 0.81, 0, 0.98) 0s;
    background-color: #333333;
    text-align: left;
    padding-top: 48px;
    padding-left: 48px;
    padding-bottom: 44px;
    padding-right: 44px;
    width: 456px;
    position: absolute;
    bottom: -30px;
}

.riotbar-footer-riot-locale .riotbar-footer-region-changer:lang(ar) {
    direction: rtl;
    text-align: right;
}

.riotbar-footer-riot-locale .riotbar-footer-region-changer .header {
    font-size: 26px;
    margin-bottom: 29px;
}

.riotbar-footer-riot-locale .riotbar-footer-region-changer .region-changer-locale-close {
    position: absolute;
    right: 14px;
    top: 14px;
    cursor: pointer;
}

.riotbar-footer-riot-locale .riotbar-footer-region-changer .region-changer-locale-close:lang(ar) {
    right: auto;
    left: 14px;
}

.riotbar-footer-riot-locale .riotbar-footer-region-changer .region-changer-copy {
    color: #E8E8E8;
}

.riotbar-footer-riot-locale .riotbar-footer-region-changer .region-chooser-button-holder,
.riotbar-footer-riot-locale .riotbar-footer-region-changer .region-changer-copy {
    margin-bottom: 29px;
}

.riotbar-footer-riot-locale .riotbar-footer-region-changer button {
    background-color: #282828;
}

.riotbar-footer-riot-locale .riotbar-footer-region-changer #switch-regions-now {
    color: #F9F9F9;
}

.riotbar-footer-riot-locale button {
    cursor: pointer;
    height: 48px;
    width: 248px;
    background-color: #282828;
    color: #7E7E7E;
    border-radius: 5px;
    border-width: 0;
    /* These custom font references are actually loaded in when the `customFonts` plugin is enabled */
    /* Main font by default */
    font-family: "FF Mark W05";
    font-weight: 800;
    text-transform: none;
    letter-spacing: 0;
    font-size: 11px;
    text-align: left;
    padding: 0px 16px;
}

.riotbar-footer-riot-locale button:lang(ar) {
    font-family: "Cairo", Tahoma, sans-serif;
}

.riotbar-footer-riot-locale button:lang(ru) {
    font-family: "Neue Frutiger World W05", Tahoma, sans-serif;
}

.riotbar-footer-riot-locale button:lang(ko),
.riotbar-footer-riot-locale button:lang(kr) {
    font-family: "RixSGo", Tahoma, sans-serif;
}

.riotbar-footer-riot-locale button:lang(ar) {
    text-align: right;
}

.riotbar-footer-riot-locale button.go {
    color: #111111;
}

.riotbar-footer-riot-locale button svg {
    float: right;
    margin-top: 4px;
}

.riotbar-footer-riot-locale button svg:lang(ar) {
    float: left;
}

.riotbar-footer-riot-locale button svg path {
    fill: #7E7E7E;
}

@media screen and (max-width: 1024px) {
    .riotbar-footer-riot-locale {
        max-width: 100%;
    }
}

.localization-management-list {
    /* These custom font references are actually loaded in when the `customFonts` plugin is enabled */
    /* Main font by default */
    font-family: "FF Mark W05";
    display: none;
    width: 240px;
    background-color: #F9F9F9;
    border-radius: 4px;
}

.localization-management-list:lang(ar) {
    font-family: "Cairo", Tahoma, sans-serif;
}

.localization-management-list:lang(ru) {
    font-family: "Neue Frutiger World W05", Tahoma, sans-serif;
}

.localization-management-list:lang(ko),
.localization-management-list:lang(kr) {
    font-family: "RixSGo", Tahoma, sans-serif;
}

.localization-management-list.active {
    display: block;
}

.localization-management-list ul {
    margin: 0;
    padding: 0;
    text-align: left;
    font-size: 12px;
    max-height: 360px;
    overflow-x: auto;
}

.localization-management-list ul:lang(ar) {
    direction: rtl;
    text-align: right;
}

.localization-management-list ul li {
    display: block;
    height: 40px;
    line-height: 40px;
    padding: 0;
    cursor: pointer;
}

.localization-management-list ul li .lang-name {
    text-transform: uppercase;
    display: inline-block;
    width: 20px;
}

.localization-management-list ul li a,
.localization-management-list ul li a:visited,
.localization-management-list ul li a:link {
    transition: color 0.3s linear;
    position: relative;
    display: inline-block;
    text-decoration: none;
    color: #999999;
    width: 100%;
    padding: 0 0 0 20px;
}

.localization-management-list ul li a:lang(ar),
.localization-management-list ul li a:visited:lang(ar),
.localization-management-list ul li a:link:lang(ar) {
    padding: 0 20px 0 0;
}

.localization-management-list ul li a svg,
.localization-management-list ul li a:visited svg,
.localization-management-list ul li a:link svg {
    position: absolute;
    top: 35%;
    right: 20px;
    width: 14px;
    height: 12px;
    fill: #F9F9F9;
}

.localization-management-list ul li a svg:lang(ar),
.localization-management-list ul li a:visited svg:lang(ar),
.localization-management-list ul li a:link svg:lang(ar) {
    right: auto;
    left: 20px;
}

.localization-management-list ul li:hover a {
    color: #111111;
}

.localization-management-list ul li.active {
    cursor: default;
}

.localization-management-list ul li.active a {
    color: #c4202b;
}

.riotbar-locale .icon-lang-switch {
    position: relative;
    /* These custom font references are actually loaded in when the `customFonts` plugin is enabled */
    /* Main font by default */
    font-family: "FF Mark W05";
}

.riotbar-locale .icon-lang-switch:lang(ar) {
    font-family: "Cairo", Tahoma, sans-serif;
}

.riotbar-locale .icon-lang-switch:lang(ru) {
    font-family: "Neue Frutiger World W05", Tahoma, sans-serif;
}

.riotbar-locale .icon-lang-switch:lang(ko),
.riotbar-locale .icon-lang-switch:lang(kr) {
    font-family: "RixSGo", Tahoma, sans-serif;
}

.riotbar-locale .icon-lang-switch .lang-switch-dropdown:before {
    content: " ";
    height: 0;
    position: absolute;
    width: 0;
    left: 113px;
    top: -20px;
    border: 10px solid transparent;
    border-bottom-color: #F9F9F9;
}

@media (max-width: 1024px) {
    .riotbar-locale .icon-lang-switch .lang-switch-dropdown:before {
        left: 153px;
    }
}

.riotbar-locale .icon-lang-switch .lang-switch-dropdown {
    position: absolute;
    top: 90px;
    left: -115px;
    padding: 8px 0 8px 8px;
}

@media (max-width: 1024px) {
    .riotbar-locale .icon-lang-switch .lang-switch-dropdown {
        left: -155px;
    }
}

.sideMenuIcons .riotbar-locale .icon-lang-switch .lang-switch-dropdown {
    top: 50px;
    right: 0;
    left: unset;
}

.sideMenuIcons .riotbar-locale .icon-lang-switch .lang-switch-dropdown:before {
    left: unset;
    right: 20px;
    top: -20px;
}

.riotbar-locale:lang(ar) {
    direction: rtl;
}

.riotbar-social-links ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
}

.riotbar-social-links {
    user-select: none;
    box-sizing: border-box;
}

.riotbar-social-links * {
    box-sizing: border-box;
}

.riotbar-social-links ul li a .image-alt-text {
    opacity: 0;
    user-select: none;
    color: transparent;
    text-decoration: none;
}

.riotbar-social-links ul li {
    margin-left: 10px;
    margin-right: 10px;
}

.riotbar-social-links ul li a {
    display: block;
    margin: 4px;
    overflow: hidden;
    text-decoration: none;
    opacity: 0.3;
    transition: opacity 0.2s linear;
    cursor: pointer;
}

.riotbar-social-links ul li a .image-alt-text {
    display: inline-block;
    width: 0;
}

.riotbar-social-links ul li a:hover,
.riotbar-social-links ul li a:active,
.riotbar-social-links ul li a:focus {
    opacity: 1;
}

.riotbar-social-links ul li a svg {
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.riotbar-social-links ul li a.link-social svg {
    width: 24px;
    height: 24px;
}

.riotbar-copyright {
    /* These custom font references are actually loaded in when the `customFonts` plugin is enabled */
    /* Main font by default */
    font-family: "FF Mark W05";
    font-weight: 500;
    font-size: 11px;
    line-height: 1.316;
    margin: 0 auto;
    max-width: 500px;
    text-align: center;
    color: #7E7E7E;
    transition: color 0.2s linear;
    word-break: keep-all;
}

.riotbar-copyright:lang(ar) {
    font-family: "Cairo", Tahoma, sans-serif;
}

.riotbar-copyright:lang(ru) {
    font-family: "Neue Frutiger World W05", Tahoma, sans-serif;
}

.riotbar-copyright:lang(ko),
.riotbar-copyright:lang(kr) {
    font-family: "RixSGo", Tahoma, sans-serif;
}

.riotbar-copyright a {
    color: #7E7E7E;
    text-decoration: underline;
}

.riotbar-copyright a:hover,
.riotbar-copyright a:active,
.riotbar-copyright a:focus {
    color: #7E7E7E;
}

.riotbar-copyright:lang(ar) {
    font-family: Tahoma, sans-serif;
}

@media (max-width: 500px) {
    .riotbar-copyright {
        max-width: 310px;
    }
}

.riotbar-legal-links ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
}

.riotbar-legal-links {
    user-select: none;
    box-sizing: border-box;
}

.riotbar-legal-links * {
    box-sizing: border-box;
}

/* Breakpoints */
/* Common Mixins */
.riotbar-legal-links {
    /* These custom font references are actually loaded in when the `customFonts` plugin is enabled */
    /* Main font by default */
    font-family: "FF Mark W05";
    font-weight: 500;
    font-size: 11px;
    color: #7E7E7E;
    text-align: center;
}

.riotbar-legal-links:lang(ar) {
    font-family: "Cairo", Tahoma, sans-serif;
}

.riotbar-legal-links:lang(ru) {
    font-family: "Neue Frutiger World W05", Tahoma, sans-serif;
}

.riotbar-legal-links:lang(ko),
.riotbar-legal-links:lang(kr) {
    font-family: "RixSGo", Tahoma, sans-serif;
}

.riotbar-legal-links:lang(ar) {
    font-family: Tahoma, sans-serif;
}

.riotbar-legal-links ul {
    flex-wrap: wrap;
}

.riotbar-legal-links ul li span.updated {
    color: #ff0000;
    font-weight: bold;
    text-transform: uppercase;
}

.riotbar-legal-links ul li a {
    display: block;
    margin: 0 5px;
    text-decoration: underline;
    color: #7E7E7E;
    transition: all 0.2s linear;
}

.riotbar-legal-links ul li a:visited {
    color: #7E7E7E;
}

.riotbar-legal-links ul li a:hover,
.riotbar-legal-links ul li a:active,
.riotbar-legal-links ul li a:focus {
    color: #7E7E7E;
}

.riotbar-legal-links ul li a.bold {
    font-weight: bold;
}

.riotbar-legal-links ul li a.underline {
    text-decoration: underline;
}

.riotbar-legal-links ul li a.updated {
    display: inline;
}

.riotbar-legal-links ul li a.link-privacyPolicy {
    font-weight: bold;
}

.riotbar-game-ratings {
    user-select: none;
    box-sizing: border-box;
}

.riotbar-game-ratings * {
    box-sizing: border-box;
}

.riotbar-game-ratings .riotbar-game-rating-logo .image-alt-text {
    opacity: 0;
    user-select: none;
    color: transparent;
    text-decoration: none;
}

/* Breakpoints */
/* Common Mixins */
.riotbar-game-ratings {
    font-weight: 500;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.riotbar-game-ratings .riotbar-game-rating {
    margin: 0 10px;
    max-width: 100%;
}

.riotbar-game-ratings .riotbar-game-rating-text-wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
}

.riotbar-game-ratings .riotbar-game-rating-logo {
    display: block;
    background-color: transparent;
    background-repeat: no-repeat;
    background-size: contain;
    flex-shrink: 0;
    margin: 5px 0;
    max-width: 100%;
}

.riotbar-game-ratings .riotbar-game-rating-text {
    display: none;
}

.riotbar-game-ratings .na-esrb .riotbar-game-rating-logo,
.riotbar-game-ratings .latam-esrb .riotbar-game-rating-logo,
.riotbar-game-ratings .teen-rating .riotbar-game-rating-logo,
.riotbar-game-ratings .esrb-ratingpending-en .riotbar-game-rating-logo,
.riotbar-game-ratings .esrb-ratingpending-es .riotbar-game-rating-logo {
    background-image: url("https://cdn.leagueoflegends.com/riotbar/prod/2.0.108/images/footer/game-rating-logos/na-esrb.png");
    width: 65px;
    height: 97px;
}

.riotbar-game-ratings .na-esrb .riotbar-game-rating-text,
.riotbar-game-ratings .latam-esrb .riotbar-game-rating-text,
.riotbar-game-ratings .teen-rating .riotbar-game-rating-text,
.riotbar-game-ratings .esrb-ratingpending-en .riotbar-game-rating-text,
.riotbar-game-ratings .esrb-ratingpending-es .riotbar-game-rating-text {
    display: inline-block;
    text-align: left;
    color: #efefef;
    margin-left: 6px;
    line-height: 19px;
    font-size: 15px;
    /* These custom font references are actually loaded in when the `customFonts` plugin is enabled */
    /* Main font by default */
    font-family: "FF Mark W05";
}

.riotbar-game-ratings .na-esrb .riotbar-game-rating-text:lang(ar),
.riotbar-game-ratings .latam-esrb .riotbar-game-rating-text:lang(ar),
.riotbar-game-ratings .teen-rating .riotbar-game-rating-text:lang(ar),
.riotbar-game-ratings .esrb-ratingpending-en .riotbar-game-rating-text:lang(ar),
.riotbar-game-ratings .esrb-ratingpending-es .riotbar-game-rating-text:lang(ar) {
    font-family: "Cairo", Tahoma, sans-serif;
}

.riotbar-game-ratings .na-esrb .riotbar-game-rating-text:lang(ru),
.riotbar-game-ratings .latam-esrb .riotbar-game-rating-text:lang(ru),
.riotbar-game-ratings .teen-rating .riotbar-game-rating-text:lang(ru),
.riotbar-game-ratings .esrb-ratingpending-en .riotbar-game-rating-text:lang(ru),
.riotbar-game-ratings .esrb-ratingpending-es .riotbar-game-rating-text:lang(ru) {
    font-family: "Neue Frutiger World W05", Tahoma, sans-serif;
}

.riotbar-game-ratings .na-esrb .riotbar-game-rating-text:lang(ko),
.riotbar-game-ratings .na-esrb .riotbar-game-rating-text:lang(kr),
.riotbar-game-ratings .latam-esrb .riotbar-game-rating-text:lang(ko),
.riotbar-game-ratings .latam-esrb .riotbar-game-rating-text:lang(kr),
.riotbar-game-ratings .teen-rating .riotbar-game-rating-text:lang(ko),
.riotbar-game-ratings .teen-rating .riotbar-game-rating-text:lang(kr),
.riotbar-game-ratings .esrb-ratingpending-en .riotbar-game-rating-text:lang(ko),
.riotbar-game-ratings .esrb-ratingpending-en .riotbar-game-rating-text:lang(kr),
.riotbar-game-ratings .esrb-ratingpending-es .riotbar-game-rating-text:lang(ko),
.riotbar-game-ratings .esrb-ratingpending-es .riotbar-game-rating-text:lang(kr) {
    font-family: "RixSGo", Tahoma, sans-serif;
}

@media (max-width: 400px) {

    .riotbar-game-ratings .na-esrb .riotbar-game-rating-text,
    .riotbar-game-ratings .latam-esrb .riotbar-game-rating-text,
    .riotbar-game-ratings .teen-rating .riotbar-game-rating-text,
    .riotbar-game-ratings .esrb-ratingpending-en .riotbar-game-rating-text,
    .riotbar-game-ratings .esrb-ratingpending-es .riotbar-game-rating-text {
        font-size: 12px;
    }
}

.riotbar-game-ratings .latam-esrb .riotbar-game-rating-logo {
    background-image: url("https://cdn.leagueoflegends.com/riotbar/prod/2.0.108/images/footer/game-rating-logos/latam-esrb.png");
}

.riotbar-game-ratings .esrb-ratingpending-en .riotbar-game-rating-logo {
    background-image: url("https://cdn.leagueoflegends.com/riotbar/prod/2.0.108/images/footer/game-rating-logos/esrb-rp-t-en.png");
    height: 99px;
    width: 203px;
}

.riotbar-game-ratings .esrb-ratingpending-es .riotbar-game-rating-logo {
    background-image: url("https://cdn.leagueoflegends.com/riotbar/prod/2.0.108/images/footer/game-rating-logos/esrb-rp-t-es.png");
    height: 99px;
    width: 258px;
}

.riotbar-game-ratings .pegi .riotbar-game-rating-logo {
    background-image: url("https://cdn.leagueoflegends.com/riotbar/prod/2.0.108/images/footer/game-rating-logos/pegi.png");
    width: 56px;
    height: 68px;
}

.riotbar-game-ratings .pegi-16 .riotbar-game-rating-logo {
    background-image: url("https://cdn.leagueoflegends.com/riotbar/prod/2.0.108/images/footer/game-rating-logos/pegi-16.png");
    width: 56px;
    height: 69px;
}

.riotbar-game-ratings .pegi-16-provisional .riotbar-game-rating-logo {
    background-image: url("https://cdn.leagueoflegends.com/riotbar/prod/2.0.108/images/footer/game-rating-logos/pegi-16-provisional-en.png");
    width: 94px;
    height: 85px;
}

.riotbar-game-ratings .pegi-16-provisional-de .riotbar-game-rating-logo {
    background-image: url("https://cdn.leagueoflegends.com/riotbar/prod/2.0.108/images/footer/game-rating-logos/pegi-16-provisional-de.png");
    width: 106px;
    height: 85px;
}

.riotbar-game-ratings .pegi-16-provisional-fr .riotbar-game-rating-logo {
    background-image: url("https://cdn.leagueoflegends.com/riotbar/prod/2.0.108/images/footer/game-rating-logos/pegi-16-provisional-fr.png");
    width: 86px;
    height: 85px;
}

.riotbar-game-ratings .pegi-16-provisional-it .riotbar-game-rating-logo {
    background-image: url("https://cdn.leagueoflegends.com/riotbar/prod/2.0.108/images/footer/game-rating-logos/pegi-16-provisional-it.png");
    width: 96px;
    height: 85px;
}

.riotbar-game-ratings .pegi-16-provisional-pl .riotbar-game-rating-logo {
    background-image: url("https://cdn.leagueoflegends.com/riotbar/prod/2.0.108/images/footer/game-rating-logos/pegi-16-provisional-pl.png");
    width: 86px;
    height: 85px;
}

.riotbar-game-ratings .usk-12 .riotbar-game-rating-logo {
    background-image: url("https://cdn.leagueoflegends.com/riotbar/prod/2.0.108/images/footer/game-rating-logos/usk-12.png");
    width: 68px;
    height: 68px;
}

.riotbar-game-ratings .br-rating .riotbar-game-rating-logo {
    background-image: url("https://cdn.leagueoflegends.com/riotbar/prod/2.0.108/images/footer/game-rating-logos/br-rating.png");
    width: 149px;
    height: 87px;
}

.riotbar-game-ratings .oce-rating .riotbar-game-rating-logo {
    background-image: url("https://cdn.leagueoflegends.com/riotbar/prod/2.0.108/images/footer/game-rating-logos/oce-rating.png");
    width: 227px;
    height: 75px;
}

.riotbar-game-ratings .kr-rating .riotbar-game-rating-logo {
    background-image: url("https://cdn.leagueoflegends.com/riotbar/prod/2.0.108/images/footer/game-rating-logos/kr-rating.png");
    width: 450px;
    height: 71px;
}

.riotbar-game-ratings .ru-rating .riotbar-game-rating-logo {
    background-image: url("https://cdn.leagueoflegends.com/riotbar/prod/2.0.108/images/footer/game-rating-logos/ru-rating.png");
    width: 50px;
    height: 60px;
}

.riotbar-game-ratings .teen-rating .riotbar-game-rating-logo {
    background-image: url("https://cdn.leagueoflegends.com/riotbar/prod/2.0.108/images/footer/game-rating-logos/teen-rating.png");
    width: 87px;
    height: 133px;
}

.riotbar-game-ratings .br-14-rating .riotbar-game-rating-logo {
    background-image: url("https://cdn.leagueoflegends.com/riotbar/prod/2.0.108/images/footer/game-rating-logos/br-rating-14.png");
    width: 225px;
    height: 129px;
}