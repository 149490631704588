.popup-overlay.fileError-overlay {
  background: rgba(255, 255, 255, 0.5) !important;
  .popup-content {
    width: 620px !important;
    background-color: #141414 !important;
    border: 0 !important;
    border-radius: 10px;
    .layer-in {
      padding: 28px 0;
      .col-cont-tit {
        color: #f9f9f9;
        font-size: 16px;
        font-weight: normal;
      }
      .close {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        background-color: transparent;
        font: 0/0 a;
      }
    }
  }
}
