.input_r {
    display:inline-block;
    vertical-align: middle;
    input {
        overflow:hidden;
        position:absolute;
        left:-1000px;
        width:1px;
        height:1px;
        margin:-1px;
        padding:0;
        border:0;
        clip:rect(0, 0, 0, 0);
        z-index:-1000;
        visibility:hidden;
    }
    input[type=radio] + label {
        display: block;
        position: relative;
        font-family: 'RixSGoB', sans-serif;
        color: #111111;
        font-size:14px;
        padding-left:25px;
        line-height:16px;
        min-height: 16px;
        text-align: left;
        cursor: pointer;
        &:before {
            display: block;
            content: '';
            position: absolute;
            top: 50%;
            left: 0px;
            width:16px;
            height:16px;
            margin-top: -7px;
            background:url("../../../../assets/images/cirlcle_normal.png") no-repeat 0 0;
        }
        span {
            display: inline-block;
            vertical-align: middle; 
        }
        img {
            pointer-events: none;
        }
    }
    input[type=radio]:checked + label:before {
        background-image:url("../../../../assets/images/cirlcle_selected.png");
    }
}