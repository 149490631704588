.popup-overlay.restriction-overlay {
  background: rgba(255, 255, 255, 0.5) !important;
  .popup-content {
    width: 620px !important;
    background-color: #141414 !important;
    border: 0 !important;
    border-radius: 10px;
  }
  .layer-in {
    padding: 75px 90px 65px;
    .col-cont-tit-box {
      .col-cont-tit {
        font-size: 20px;
        color: #fff;
        line-height: 1.4;
        font-weight: 400;
      }
      span {
        display: block;
        margin-top: 5px;
        font-size: 14px;
        color: #b0b0b0;
        line-height: 1.4;
      }
    }
    .col-cont-agree-box {
      padding: 0;
      margin: 35px 0 0;
      background-color: #141414;
      ul li {
        border: 1px solid rgba(79, 79, 79, 0.3);
        display: table;
        width: 100%;
        + li {
          margin-top: 3px;
        }
        .tit {
          background-color: rgba(79, 79, 79, 0.3);
          display: table-cell;
          vertical-align: middle;
          width: 195px;
          padding: 20px 0 15px 25px;
          span {
            font-size: 16px;
            color: #b0b0b0;
            line-height: 1.4;
          }
        }
        .txt {
          display: table-cell;
          vertical-align: middle;
          padding: 20px 0 15px;
          text-align: center;
          span {
            font-size: 16px;
            color: #5bc6e3;
            line-height: 1.4;
          }
        }
        .tit span:before {
          display: inline-block;
          content: '';
          width: 30px;
          height: 30px;
          background-repeat: no-repeat;
          background-position: 0 0;
          vertical-align: middle;
          margin-right: 10px;
        }
        &.lol .tit span:before {
          background-image: url('../../../images/layer_icon_lol.png');
        }
        &.lor .tit span:before {
          background-image: url('../../../images/layer_icon_lor.png');
          background-position: -2px 0;
        }
        &.valorant .tit span:before {
          background-image: url('../../../images/layer_icon_valorant.png');
        }
      }
    }

    .close {
      display: block;
      width: 24px;
      height: 24px;
      font: 0/0 a;
      background: url('../../../images/layer_close.png') no-repeat 0 0;
      position: absolute;
      top: 24px;
      right: 24px;
    }
  }
}
