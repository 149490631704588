.input_c {
    display:inline-block;
    vertical-align: middle;
    input {
        overflow:hidden;
        position:absolute;
        left:-1000px;
        width:1px;
        height:1px;
        margin:-1px;
        padding:0;
        border:0;
        clip:rect(0, 0, 0, 0);
        z-index:-1000;
        visibility:hidden;
    }
    input[type=checkbox] + label {
        display: block;
        position: relative;
        font-family: 'RixSGoB', sans-serif;
        color: #111111;
        font-size:15px;
        padding-left:35px;
        line-height:20px;
        min-height: 24px;
        text-align: left;
        cursor: pointer;
        color: #838383;
        &:before {
            display: block;
            content: '';
            position: absolute;
            left: 0px;
            width:24px;
            height:24px;
            top: -2px;
            background:url("../../../../assets/images/chk_unchecked.png") no-repeat 0 0;
        }
        span {
            display: inline-block;
            vertical-align: middle; 
        }
        img {
            pointer-events: none;
        }
    }
    input[type=checkbox]:checked + label:before {
        background-image:url("../../../../assets/images/chk_checked.png");
    }
}