.dropzone-wrap {
  margin-bottom: 60px;
  .dropzone {
    background: rgba(130, 130, 130, 0.2);
    border-radius: 6px;
    border: 0;
    width: 791px;
    min-height: 321px;
    cursor: pointer;
    overflow: hidden;
    position: relative;
    display: flex;
    .normal {
      display: flex;
      border-radius: 6px;
      border: 0;
      width: 791px;
      min-height: 321px;
      flex-flow: column;
      justify-content: center;
      align-items: center;
      padding-bottom: 30px;
      i {
        display: block;
        font: 0/0 a;
        width: 76px;
        height: 64px;
        background: url('../../images/dropzone_Ico.png') no-repeat 0 0;
      }
      p {
        font-size: 16px;
        font-family: 'RixSGoB', sans-serif;
        line-height: 1.2;
        text-align: center;
        color: #838383;
        margin-top: 20px;
      }
    }
    .error p {
      color: #d03739;
    }
    aside {
      border-radius: 6px;
      border: 0;
      width: 791px;
      overflow: hidden;
      max-height: 260px;
      li {
        width: 791px;
        max-height: 260px;
        flex-flow: column;
        justify-content: center;
        align-items: center;
        display: flex;
        .sum {
          width: 200px;
          height: 200px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-top: 10px;
          img {
            flex-shrink: 0;
          }
        }
        img {
          display: block;
          max-width: 100%;
          max-height: 100%;
          //width: auto;
        }
        .txt {
          margin-top: 10px;
          text-align: center;
          span {
            display: inline-block;
            font-size: 16px;
            font-family: 'RixSGoB', sans-serif;
            line-height: 1.2;
            text-align: center;
            color: #0359ff;
            text-decoration: underline;
          }
          button {
            margin-left: 10px;
            display: inline-block;
            width: 20px;
            height: 20px;
            background: url('../../images/close_file.png') no-repeat 0 0;
            font: 0/0 a;
          }
        }
      }
    }
  }
  &.on .dropzone {
    border: 2px solid #b0b0b0;
    box-sizing: border-box;
    background-color: transparent;
  }
}
