.popup-overlay {
  flex-direction: column;
  z-index: 9999999 !important;
  .popup-content {
    padding: 0 !important;
    width: 1100px !important;
  }
  .layer-in {
    max-height: calc(100vh - 150px);
    overflow-x: hidden;
    overflow-y: auto;
    padding: 50px 0;
  }
}
