.delegate-wrap .delegate-in .input-w.absolute-po {
  width: 100% !important;
}
.change-input {
  > .select-container {
    min-width: 190px;
  }
  .banking {
    display: inline-block;
    vertical-align: top;
    .input_t {
      display: inline-block;
      vertical-align: top;
      margin-left: 10px;
      width: 120px;
      & + .input_t {
        margin-top: 0 !important;
        width: 310px;
      }
    }
  }
  .mobile-card {
    display: inline-block;
    vertical-align: top;
    .select-container {
      display: inline-block;
      vertical-align: top;
      margin-left: 10px;
      width: 120px;
    }
    .input_t {
      display: inline-block;
      vertical-align: top;
      margin-top: 0 !important;
      margin-left: 10px;
      width: 310px;
    }
  }
  .other {
    display: inline-block;
    vertical-align: top;
    .input_t {
      display: inline-block;
      vertical-align: top;
      margin-top: 0 !important;
      margin-left: 10px;
      width: 310px;
    }
  }
}
