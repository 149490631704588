.delegate-wrap {
    width: 810px;
    margin: 0 auto;
    text-align: left;
    color: #222325;
    font-family: 'RixSGoB', sans-serif;
    .col-cont-agree-tit{
        color:#403f3f;
        text-align: left;
        span {
            display: block;
            text-align: center;
            font-size: 24px;
            font-weight: bold;
        }
    }
    .col-cont-agree-txt{
        color:#838383;
        margin-top:25px;
        letter-spacing: -1px;
        ul li{
            text-indent:13px;
        }
    }
    h3 {
        font-size: 20px;
        font-weight: bold;
    }
    h4 {
        font-size: 18px;
        font-weight: bold; 
        margin:0 0 5px 
    }
    .delegate-in {
        strong {
            font-size: 16px;
            font-weight: 400;
            display: block;
            margin-bottom: 5px;
        }
        +.delegate-in {
            margin-top: 40px;
        }
        > ul {
            > li{
                margin-top: 15px;
                font-size: 0;
            }
        }
        .input-w {
            width: 38%;
            display: inline-block;
            vertical-align: top;
            + .input-w {
                width: 60%;
                margin-left: 2%;
            }
            .input_t + .input_t {
                margin-top: 10px;
            }
        }
        .ty2 .input-w {
            width: 49%;
        }
        .guide {
            color: #838383;
            font-size: 14px;
            line-height: 20px;
        }
        .error {
            text-align: left;
            font-size: 14px;
            margin-top: 3px;
            color: #d03739;
            font-weight: 400;
        }
    }
    .field-array {
        > li {
            margin-bottom: 10px;
        }
    }
}