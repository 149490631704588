.input_date {
    padding: 0px;
    border: 2px solid transparent;
    background: rgba(126, 126, 126, 0.1);
    box-sizing: border-box;
    font-size: 0;
    border-radius: 4px;
    &.focus {
        border: 2px solid #000;
        background-color: #fff;
    }
    &.on {
        background: #ededed;
    }
    > div {
        display: inline-block;
        vertical-align: middle;
        width: 33.333%;
        position: relative;
        input {
            background-color: transparent;
            border: 0;
            width: 100%;
            height: 70px;
            font-weight: 700;
            box-sizing: border-box;
            display: block;
            line-height: 1.5;
            font-size: 20px;
            padding: 30px 10px 0;
        }
        span {
            opacity: 0;
            transition: all 0.25s cubic-bezier(0.4, 0, 0.2, 1); 
            color:#999;
            display: block;
            text-align: left;
            font-size: 14px;
            position: absolute;
            top:10px;
            left:10px;
            pointer-events: none;
            transform: scale(0.75);
        }
        label {
            opacity: 1;
            transition: all 0.25s cubic-bezier(0.4, 0, 0.2, 1);
            color:#999;
            display: block;
            text-align: left;
            font-size: 14px;
            position: absolute;
            top:24px;
            left:0px;
            width: 100%;
            text-align: center;
            pointer-events: none;
            transform: scale(1);
        }
        + div:before {
            display: block;
            content: "";
            width: 1px;
            height: 30px;
            background-color: #ccc;
            position: absolute;
            top:50%;
            left:0;
            margin-top: -15px;
        }
        &.on {
            span {
                opacity: 1;
                transform: scale(1);
            }
            label {
                opacity: 0;
                transform: scale(0.75);
            }
        }
        input:focus {
            ~ span {
                opacity: 1;
                transform: scale(1);
            }
            ~ label {
                opacity: 0;
                transform: scale(0.75);
            }
        }
    }
}